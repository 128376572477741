import React from 'react'

/*
* Let's store some variables to use it globaly
*/
export function getVariables() {
    //common variables
    const options = {
        'site': 'fr.mattress.zone',
        'home': 'Accueil',
        'mattresses': 'matelas',
        'mattress': 'le-matelas',
        'mattress_vs': '-vs-le-matelas-',
        'compare': 'comparer',
        'comparer': 'comparer',
        'comparison': 'comparaison',
        'review': 'revoir',
        'reviews': 'commentaires',
        'contact': 'contacto',
        'privacy': 'privacité',
        'message_limit': 'Vous avez sélectionné le maximum de produits autorisés à comparer. Veuillez retirer un matelas pour en ajouter un autre (trois permis).',
        'last_updated_on': 'Dernière mise à jour le',
        'add_another_mattress': 'Ajouter un autre matelas',
        'popular_comparisons': 'Comparaisons populaires',
        'enter_mattress_name': 'Entrez le nom du matelas',
        'add_title': 'Sélectionnez ce matelas pour comparer',
        'list_mattress_text': 'Sélectionnez l\'un de ces matelas qui sont fréquemment comparés',
        'view_article': 'Voir l\'article',
        'remove': 'Retirer l\'objet',
        'view_all_reviews': 'Voir tous les avis',
        'recent_reviews': 'Avis récents',
        'shop_now': 'Acheter maintenant',
        'read_more': 'Voir plus d\'informations',
        'price_range': 'Échelle des prix',
        'firmness': 'Fermeté',
        'mattress_type': 'Type de matelas',

        'about_us': 'À propos de nous',
        'cookie_message': 'Ce site utilise des cookies pour vous garantir la meilleure expériences sur notre site internet.',
        'find_out_more': 'En savoir plus.',
        'guides': 'Guides',
        'company': 'Enterprise',
        'region': 'région',
        'alt_promotion': 'Ceci est un lien vers le site Web de {brand}',
    }
    return options
}

/**
 * Return jsx components from a promotion array processed and ready to be inserted into a fragment
 * @param  {Array} promotions array of promotions from DATOcms
 * @return {JSX}      JSX frament
 */
export function getPromotions(promotions, brand_name) {
    let affiliate_link = null
    let shortened_affiliate_link = null
    const variables = getVariables()

    let start_date = null
    //let end_date = null   //TODO
    let show_promotion = false
    let today_date = new Date()

    let mattres_prom = []
    if (promotions && promotions.length) {
        //iterate the promotions in case there's more than one
        for (let i = 0; i < promotions.length; i++) {
            if (promotions[i].startDate) {
                start_date = new Date(promotions[0].startDate)
            }
            if (promotions[i].endDate) {
                //end_date = new Date(promotions[i].endDate)
            }

            if (promotions[i].affiliateLinkPromotion.affiliateLink) {
                affiliate_link = promotions[i].affiliateLinkPromotion.affiliateLink
            }
            if (promotions[i].affiliateLinkPromotion.shortenedAffiliateLink) {
                shortened_affiliate_link = promotions[i].affiliateLinkPromotion.shortenedAffiliateLink
            }

            if (start_date !== null) {
                //date setup so check ranges
                if (start_date.getTime() < today_date.getTime()) {
                    show_promotion = true
                }
            } else {
                show_promotion = true
            }

            //Added a default text on Chris request
            let alt_title = variables.alt_promotion
            if (promotions[i].altTitle) {
                alt_title = promotions[i].altTitle
            }
            alt_title = alt_title.replace('{brand}', brand_name)

            if (show_promotion) {
                mattres_prom.push(<div key={`item-promotions-key-${i}`}>
                    <div className="text-lg text-center text-zone-2 mb-2" dangerouslySetInnerHTML={{ __html: promotions[i].description }} />
                    <a className="btn-coupon font-grotesk text-white bg-zone-6 p-4 mb-4 block uppercase text-center font-semibold rounded-3xl duration-300 hover:bg-zone-14" href={shortened_affiliate_link ? shortened_affiliate_link : affiliate_link} target="_blank" rel="sponsored noreferrer" title={alt_title}>
                        {promotions[i].affiliateLinkCta}
                    </a>
                </div>)
            }
        }
    }
    return mattres_prom
}

/**
 * Return a link to a valid promotion ready to be inserted into an anchor
 * @param  {Array} promotions array of promotions from DATOcms
 * @return {String}      Promotion link
 */
export function getPromotionLink(mattress) {
    let affiliateLink = null
    //Iterating the mattress lets check for the brand promotion first
    if (mattress.brand.promotions && mattress.brand.promotions.length) {
        affiliateLink = iteratePromotions(mattress.brand.promotions)
    } else {
        //no brand promotion, try product promotion
        if (mattress.promotions.length > 0) {
            affiliateLink = iteratePromotions(mattress.promotions)
        }
    }
    return affiliateLink
}

export function iteratePromotions(promotions) {
    let result_link = null
    let affiliate_link = null
    let shortened_affiliate_link = null

    let start_date = null
    //let end_date = null
    let show_promotion = false
    let today_date = new Date()

    if (promotions && promotions.length) {
        //iterate the promotions in case there's more than one
        for (let i = 0; i < promotions.length; i++) {
            if (promotions[i].startDate) {
                start_date = new Date(promotions[0].startDate)
            }
            if (promotions[i].endDate) {
                //end_date = new Date(promotions[i].endDate)
            }

            if (promotions[i].affiliateLinkPromotion.affiliateLink) {
                affiliate_link = promotions[i].affiliateLinkPromotion.affiliateLink
            }
            if (promotions[i].affiliateLinkPromotion.shortenedAffiliateLink) {
                shortened_affiliate_link = promotions[i].affiliateLinkPromotion.shortenedAffiliateLink
            }

            if (start_date !== null) {
                //date setup so check ranges
                if (start_date.getTime() < today_date.getTime()) {
                    show_promotion = true
                }
            } else {
                show_promotion = true
            }

            if (show_promotion) {
                if (shortened_affiliate_link) {
                    result_link = shortened_affiliate_link
                } else {
                    if (affiliate_link) {
                        result_link = affiliate_link
                    }
                }
            }
        }
    }
    return result_link
}

export function getNewestDate(dates) {
    let result = null

    if (Array.isArray(dates) && dates.length) {
        const months = [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre'
        ]

        let localUpdate = new Date(Math.max.apply(null, dates.map(function (e) {
            return new Date(e)
        })))

        let newestUpdate = new Date(localUpdate.toLocaleString("en-US", { timeZone: "Europe/Paris" }))

        let month_id = newestUpdate.getMonth()
        let month = months[month_id]
        let day = newestUpdate.getDate()
        let year = newestUpdate.getFullYear()

        result = { 'day': day, 'month': month, 'year': year }
    }

    return result
}
